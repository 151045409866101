//@ts-nocheck
import * as React from 'react';

import { SocketContextParams, AptosNodeMessage, NodeData } from '../Types';

const SocketContext = React.createContext<SocketContextParams>({
    isSocketConnected: false,
    loadingData: true,
    nodeList: [],
    setNodeList: ([]) => {},
    sendMessage: (msg: string) => {},
    nodeData: {} as NodeData,
    isNodeConnected: false,
    connectionError: false,
} as SocketContextParams);

export function useSocket() {
    return React.useContext(SocketContext);
}

export const SocketProvider = ({ children }: { children: any }) => {
    const clientRef = React.useRef<WebSocket>(null);
    const [waitingToReconnect, setWaitingToReconnect] = React.useState(null);
    const [isSocketConnected, setIsConnected] = React.useState(false);
    const [nodeList, setNodeList] = React.useState([]);
    const [isNodeConnected, setNodeConnected] = React.useState(false);
    const [hasReceivedValidNodeData, setHasReceivedValidNodeData] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(true);
    const [nodeData, setNodeData] = React.useState({} as NodeData);
    const [connectionError, setConnectionError] = React.useState(false);
    const [nodeInitMsg, setNodeInitMsg] = React.useState('');
    const [ledgerData, setLedgerData] = React.useState<LedgerData>({});

    const sendMessage = (msg) => {
        if (!nodeInitMsg) {
            setHasReceivedValidNodeData(false);
        }
        if (isNodeConnected) {
            setNodeConnected(false);
        }
        clientRef.current!.send(msg);
        setLoadingData(true);
        if (msg !== nodeInitMsg) {
            setNodeInitMsg(msg);
        }
    };

    React.useEffect(() => {
        const node_listStorage: { host: string; api_port: number; metric_port: number }[] = JSON.parse(localStorage.getItem('node_data') || '[]');
        setNodeList(node_listStorage);
        if (waitingToReconnect) {
            return;
        }

        if (!clientRef.current) {
            const client = new WebSocket(process.env.REACT_APP_WS);
            clientRef.current = client;

            const hosts = {};
            client.onmessage = (message) => {
                const jsMsg = JSON.parse(message.data) as AptosNodeMessage;
                switch (jsMsg.state) {
                    case 'connected':
                        const node = jsMsg.node;
                        if (node.host && !hosts[node.host]) {
                            hosts[node.host] = true;
                            const node_list: { host: string; api_port: number; metric_port: number }[] = JSON.parse(localStorage.getItem('node_data') || '[]');
                            const nodeData = { host: node.host, api_port: node.api_port, metric_port: node.metric_port };
                            if (JSON.stringify(node_list).indexOf(JSON.stringify(nodeData)) === -1 && node.save_node) {
                                node_list.push(nodeData);
                                localStorage.setItem('node_data', JSON.stringify(node_list));
                                setNodeList(node_list);
                            }
                        }
                        setNodeData(jsMsg.node_data);
                        setHasReceivedValidNodeData(true);
                        setNodeConnected(true);
                        setLoadingData(false);
                        setConnectionError(false);
                        break;
                    case 'ledger':
                        setLedgerData(jsMsg.ledger);
                        break;
                    case 'timeout':
                        setConnectionError(true);
                        setLoadingData(false);
                        setNodeConnected(false);
                        setHasReceivedValidNodeData(false);
                        setTimeout(() => setConnectionError(false), 1000);
                        break;
                    default:
                        break;
                }
            };
            client.onopen = () => {
                console.log('OPEN');
                setIsConnected(true);
                setLoadingData(false);
                setConnectionError(false);
                if (nodeInitMsg) {
                    sendMessage(nodeInitMsg);
                }
            };
            client.onclose = () => {
                if (clientRef.current) {
                    // Connection failed
                    console.log('ws closed by server');
                } else {
                    // Cleanup initiated from app side, can return here, to not attempt a reconnect
                    console.log('ws closed by app component unmount');
                    return;
                }

                if (waitingToReconnect) {
                    return;
                }

                // Parse event code and log
                setNodeConnected(false);
                setIsConnected(false);
                setConnectionError(false);
                setLoadingData(true);
                console.log('ws closed');

                setWaitingToReconnect(true);

                // This will trigger another re-run, and because it is false,
                // the socket will be set up again
                setTimeout(() => setWaitingToReconnect(null), 2000);
            };
        }
        return () => {
            console.log('Cleanup');
            // Dereference, so it will set up next time

            clientRef.current.close();
            clientRef.current = null;
        };
    }, [waitingToReconnect]);

    const value: SocketContextParams = {
        socket: clientRef,
        isSocketConnected,
        hasReceivedValidNodeData,
        loadingData: loadingData,
        sendMessage,
        nodeList,
        setNodeList,
        nodeData,
        ledgerData,
        isNodeConnected,
        connectionError,
    };

    return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};
