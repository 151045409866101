//@ts-nocheck
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import CardHeader from '@mui/material/CardHeader';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import { NodeDataDescription, NodeData } from '../../Types';
//@ts-ignore
const useStyles = makeStyles((theme) => ({
    nodeDetails: {
        fontWeight: 'bold',
    },
}));

export const NodeHealth = ({ title, describe, nodeData }: { title: string; describe: NodeDataDescription; nodeData: NodeData }) => {
    const classes = useStyles();

    const getFormatedDate = (item: { name: string; key: string; time: boolean; health: boolean }) => {
        const value = nodeData[describe.type][item.key];
        if (item.time) return new Date(parseInt(value, 10)).toUTCString();
        if (item.health) {
            if (value.indexOf('ok') > -1) {
                return <Box sx={{ color: '#11ac11' }}>{value}</Box>;
            }
            return <Box sx={{ color: '#ff8a4b' }}>sui-node:unknown</Box>;
        }
        if (item.ram) {
            return <Box>{parseInt(value as string, 10) / 1000} MB</Box>;
        }
        return value;
    };
    return (
        <Paper elevation={8} sx={{ flex: { xs: '2 0 50%', sm: '2 0 50%', md: '2 0 35%', lg: '2 0 25%', xl: '2 0 20%' }, margin: '8px' }}>
            <Box sx={{ minWidth: 155 }}>
                <Card variant="">
                    <CardHeader subheader={title} sx={{ background: '#9fd4fa' }} subheaderTypographyProps={{ color: '#272727' }} />
                    <CardContent>
                        <List component="nav" aria-label="mailbox folders" sx={{ height: describe.custom_height ? describe.custom_height_px : '255px' }}>
                            {describe.items.map((item, key) => (
                                <div key={`${key}_1`}>
                                    {item.newSection ? <Box sx={{ mt: '6px', fontWeight: 700, fontSize: 12 }}>{item.newSectionTitle}</Box> : ''}
                                    <ListItem key={`${key}_2`} sx={{ height: 35 }}>
                                        <ListItemText primary={item.name} key={`${key}_3`} primaryTypographyProps={{ fontSize: 14 }} />{' '}
                                        <Typography
                                            variant={item.textStyle}
                                            gutterBottom
                                            className={classes.nodeDetails}
                                            key={`${key}_4`}
                                            sx={{ fontSize: item.time ? 12 : 14 }}
                                        >
                                            {getFormatedDate(item)}
                                        </Typography>
                                    </ListItem>
                                    <Divider />
                                </div>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Box>
        </Paper>
    );
};
