import * as React from 'react';

import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Link from '@mui/material/Link';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { orange } from '@mui/material/colors';

import AppBar from '@mui/material/AppBar';

/** Components */
import ThemeToggle from '../ThemeToggle';

/** Context */
import { useSocket } from '../../context';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Header = () => {
    const { isNodeConnected, connectionError, setNodeList } = useSocket();
    const [nodeConnected, setNodeConnected] = React.useState(isNodeConnected);
    const [nodeNotConnected, setNodeNotConnected] = React.useState(connectionError);

    React.useEffect(() => {
        setNodeConnected(isNodeConnected);
        setNodeNotConnected(connectionError);
    }, [isNodeConnected, connectionError]);
    return (
        <Box sx={{ flexGrow: 1 }}>
            {/* <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
            <Snackbar open={nodeConnected} onClose={() => setNodeConnected(false)} autoHideDuration={2000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    The connection was established
                </Alert>
            </Snackbar>
            <Snackbar open={nodeNotConnected} onClose={() => setNodeNotConnected(false)} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                    Can't retrieve Node Metric Data. There are few possible reasons: non existing host, closed port, node is down!
                </Alert>
            </Snackbar>
            <CssBaseline />
            <AppBar position="static" color="default">
                <Toolbar sx={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row', lg: 'row' } }}>
                    <Box>
                        <Link href="/" underline="none">
                            <Box sx={{ display: 'flex', alignItems: 'center', '& > :not(style)': { m: 0.5 }, justifyContent: 'center' }}>
                                <Box
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '22px',
                                        letterSpacing: '-1px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'relative',
                                        color: 'rgb(3, 26, 97)',
                                        marginTop: '-4px',
                                        WebkitBoxAlign: 'center',
                                    }}
                                >
                                    SUI<Box sx={{ display: 'inline-block', fontWeight: 400, color: 'rgb(1, 104, 250)' }}>TESTER</Box>
                                </Box>

                                <Box sx={{ color: '#ec6c03', fontSize: 15, fontWeight: 'bold' }}>v{process.env.REACT_APP_VERSION}</Box>
                            </Box>
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box>With ❤️ by Andrew | zValid</Box>
                        <Box>
                            <Link href="https://github.com/drawrowfly" underline="none" target="_blank">
                                <GitHubIcon />
                            </Link>
                            <Link href="https://twitter.com/0xAnzi" underline="none" target="_blank" sx={{ ml: 2 }}>
                                <TwitterIcon />
                            </Link>
                        </Box>
                    </Box>
                    <Box sx={{ display: { marginRight: '13px' } }}>
                        <Box sx={{ display: 'flex' }}>
                            <Tooltip title="Clear node history">
                                <IconButton
                                    aria-label="delete"
                                    size="large"
                                    onClick={() => {
                                        localStorage.removeItem('node_data');
                                        setNodeList([]);
                                    }}
                                    sx={{ mt: '-8px' }}
                                >
                                    <DeleteIcon fontSize="inherit" sx={{ color: orange[500] }} />
                                </IconButton>
                            </Tooltip>
                            <ThemeToggle />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};
