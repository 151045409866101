import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import ApiIcon from '@mui/icons-material/Api';
import LanIcon from '@mui/icons-material/Lan';
import BugReportIcon from '@mui/icons-material/BugReport';
import HttpIcon from '@mui/icons-material/Http';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SchemaIcon from '@mui/icons-material/Schema';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

/** Context */
import { useTheme, useSocket } from '../context';

/** Components */
import { Header } from '../components/Dashboard/Header';
import { NodeHealth } from '../components/NodeData';
import { Search } from './';

/** Contanst */
import { NodeDataDescription } from '../constants';

export const App = () => {
    const theme = useTheme();
    const { nodeData, hasReceivedValidNodeData, isSocketConnected } = useSocket();
    const ref = React.useRef<HTMLDivElement>(null);

    const borderClass = {
        border: '3px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '10px',
        [theme.style.breakpoints.down('md')]: {
            margin: 1,
        },
    };
    return (
        <Box sx={{ pb: 7 }} ref={ref}>
            <ThemeProvider theme={theme.style}>
                <CssBaseline />
                <Header />
                {!isSocketConnected ? <LinearProgress color="warning" /> : ''}
                <Container maxWidth={false}>
                    <Search />

                    {hasReceivedValidNodeData ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', mt: 4 }}>
                            <Box
                                sx={{
                                    minWidth: 375,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                    [theme.style.breakpoints.down('xl')]: {
                                        mb: 1,
                                        minWidth: 275,
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="Node Type" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            {nodeData.ledger.node_type}
                                        </Typography>
                                        <LanIcon sx={{ mt: '-25px', fontSize: 40 }} color="success" />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                sx={{
                                    minWidth: 375,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                    [theme.style.breakpoints.down('xl')]: {
                                        minWidth: 275,
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="Stage" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            DevNet
                                        </Typography>
                                        <BugReportIcon sx={{ mt: '-25px', fontSize: 40 }} color="success" />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                sx={{
                                    minWidth: 375,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                    [theme.style.breakpoints.down('xl')]: {
                                        minWidth: 275,
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="Sui JSON-RPC" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            v{nodeData.ledger.json_rpc_version}
                                        </Typography>
                                        <ApiIcon sx={{ mt: '-25px', fontSize: 40 }} color="success" />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                sx={{
                                    minWidth: 375,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                    [theme.style.breakpoints.down('xl')]: {
                                        minWidth: 275,
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="Node Health" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            {nodeData.ledger.node_health}
                                        </Typography>
                                        <LocalHospitalIcon sx={{ mt: '-25px', fontSize: 40 }} color="success" />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    ) : (
                        ''
                    )}

                    {hasReceivedValidNodeData ? (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', mt: 4 }}>
                            <Box
                                sx={{
                                    minWidth: 275,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                    [theme.style.breakpoints.down('xl')]: {
                                        mb: 1,
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="API" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            {nodeData.node_report.api_connected ? 'OK' : 'NOT OK'}
                                        </Typography>
                                        <LanIcon sx={{ mt: '-25px', fontSize: 40 }} color={nodeData.node_report.api_connected ? 'success' : 'warning'} />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                sx={{
                                    minWidth: 275,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="METRICS" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            {nodeData.node_report.metrics_connected ? 'OK' : 'NOT OK'}
                                        </Typography>
                                        <EqualizerIcon sx={{ mt: '-25px', fontSize: 40 }} color={nodeData.node_report.metrics_connected ? 'success' : 'warning'} />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                sx={{
                                    minWidth: 275,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="SYNC" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            {nodeData.sync.gossip_sync_count}
                                        </Typography>
                                        <SchemaIcon sx={{ mt: '-25px', fontSize: 40 }} color={`success`} />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                sx={{
                                    minWidth: 275,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="Api Port" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            {nodeData.ports.api.port}
                                        </Typography>
                                        <HttpIcon sx={{ mt: '-25px', fontSize: 40 }} color={nodeData.ports.api.open ? 'success' : 'warning'} />
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box
                                sx={{
                                    minWidth: 275,
                                    [theme.style.breakpoints.down('md')]: {
                                        width: '100%',
                                    },
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    sx={{
                                        ...borderClass,
                                    }}
                                >
                                    <CardHeader subheader="Metrics Port" />
                                    <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h5" component="div" sx={{ mt: '-25px' }}>
                                            {nodeData.ports.metrics.port}
                                        </Typography>
                                        <HttpIcon sx={{ mt: '-25px', fontSize: 40 }} color={nodeData.ports.metrics.open ? 'success' : 'warning'} />
                                    </CardContent>
                                </Card>
                            </Box>
                        </Box>
                    ) : (
                        ''
                    )}

                    <Box sx={{ mt: 4 }}>
                        {hasReceivedValidNodeData ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ mt: 4 }}>
                                    <Typography variant="h6" gutterBottom component="div" sx={{ mb: 2 }}>
                                        Raltime node data:
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <NodeHealth title="NODE INFO" describe={NodeDataDescription.ledger} nodeData={nodeData} />
                                        <NodeHealth title="SYNC" describe={NodeDataDescription.sync} nodeData={nodeData} />
                                        <NodeHealth title="TRANSACTIONS" describe={NodeDataDescription.transactions} nodeData={nodeData} />
                                        <NodeHealth title="OBJECTS" describe={NodeDataDescription.objects} nodeData={nodeData} />
                                        <NodeHealth title="RPC REQUEST COUNT" describe={NodeDataDescription.rpc_request_count} nodeData={nodeData} />
                                        <NodeHealth title="RPC REQUEST LATENCY" describe={NodeDataDescription.rpc_request_latency} nodeData={nodeData} />
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            ''
                        )}
                    </Box>
                </Container>
            </ThemeProvider>
        </Box>
    );
};
