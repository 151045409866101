/**
 * When use is not authorized
 */
import React, { useState, useEffect } from 'react';
import StreamIcon from '@mui/icons-material/Stream';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import MemoryIcon from '@mui/icons-material/Memory';

/** Context */
import { useSocket } from '../context';

export const Search = () => {
    const { loadingData, sendMessage, nodeList, ledgerData } = useSocket();
    const [host, setHost] = useState('65.21.132.85');
    const [api_port, setApiPort] = useState(9000);
    const [metric_port, setMetricPort] = useState(9184);
    const [saveNodeDetails, setSaveNodeDetails] = useState(true);

    const theme = useTheme();

    const getThatAptos = () => {
        const data = {
            state: 'get that node',
            host,
            api_port,
            metric_port,
            save_node: saveNodeDetails,
        };
        sendMessage(JSON.stringify(data));
    };

    useEffect(() => {
        if (nodeList.length) {
            setHost(nodeList[0].host);
            setApiPort(nodeList[0].api_port);
            setMetricPort(nodeList[0].metric_port);
        }
    }, []);

    const editHostDetails = (type: string, value: string) => {
        switch (type) {
            case 'host':
                setHost(value);
                break;
            case 'api_port':
                setApiPort(parseInt(value, 10));
                break;
            case 'metric_port':
                setMetricPort(parseInt(value, 10));
                break;
            default:
                return;
        }
    };

    const handleNodeSaveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveNodeDetails(event.target.checked);
    };

    const borderClass = {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '10px',
        height: 90,
        [theme.breakpoints.down('md')]: {
            margin: 1,
        },
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', mt: 4 }}>
                <Box
                    sx={{
                        minWidth: 375,

                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                        [theme.breakpoints.down('xl')]: {
                            mb: 1,
                            minWidth: 300,
                        },
                    }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            ...borderClass,
                        }}
                    >
                        <CardHeader subheader="Registred Nodes In Discord" />
                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6" component="div" sx={{ mt: '-25px' }}>
                                {ledgerData.total_reported_nodes ? ledgerData.total_reported_nodes : 0}
                            </Typography>
                            <MemoryIcon sx={{ mt: '-25px', fontSize: 30 }} color="success" />
                        </CardContent>
                    </Card>
                </Box>
                <Box
                    sx={{
                        minWidth: 375,
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                        [theme.breakpoints.down('xl')]: {
                            mb: 1,
                            minWidth: 300,
                        },
                    }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            ...borderClass,
                        }}
                    >
                        <CardHeader subheader="Discord Members" />
                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6" component="div" sx={{ mt: '-25px' }}>
                                {ledgerData.discord ? ledgerData.discord.approximate_member_count : 0}
                            </Typography>
                            <GroupsIcon sx={{ mt: '-25px', fontSize: 30 }} color="success" />
                        </CardContent>
                    </Card>
                </Box>
                <Box
                    sx={{
                        minWidth: 375,
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                        },
                        [theme.breakpoints.down('xl')]: {
                            mb: 1,
                            minWidth: 300,
                        },
                    }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            ...borderClass,
                        }}
                    >
                        <CardHeader subheader="Discord Members Online" />
                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6" component="div" sx={{ mt: '-25px' }}>
                                {ledgerData.discord ? ledgerData.discord.approximate_presence_count : 0}
                            </Typography>
                            <GroupsIcon sx={{ mt: '-25px', fontSize: 30 }} color="success" />
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box sx={{ mt: 3 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        '& > :not(style)': { m: 1 },
                        justifyContent: 'center',
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            },
                        }}
                    >
                        {nodeList.length ? (
                            <Box
                                sx={{
                                    width: { xs: '100%', sm: '100%', md: '100%', lg: 300 },
                                    mr: { xs: 0, sm: 0, md: 0, lg: 1 },
                                    mb: { xs: 1, sm: 1, md: 1, lg: 0, xl: 0 },
                                    display: 'flex',
                                }}
                            >
                                <Autocomplete
                                    disablePortal
                                    color="primary"
                                    id="combo-box-demo"
                                    options={nodeList}
                                    fullWidth
                                    getOptionLabel={(option) => option.host}
                                    onChange={(_, v) => {
                                        if (v) {
                                            setHost(v.host);
                                            setApiPort(v.api_port);
                                            setMetricPort(v.metric_port);
                                        }
                                    }}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.host} {option.api_port} {option.metric_port}
                                        </Box>
                                    )}
                                    renderInput={(params) => <TextField {...params} label="Saved nodes" />}
                                />
                            </Box>
                        ) : (
                            ''
                        )}

                        <FormControl sx={{ width: { xs: '100%', sm: '100%', md: nodeList.length ? '100%' : 650, lg: nodeList.length ? 750 : 1100 } }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Node ip</InputLabel>
                            <OutlinedInput
                                onChange={(e) => editHostDetails('host', e.target.value)}
                                id="host"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <FormControlLabel control={<Checkbox checked={saveNodeDetails} onChange={handleNodeSaveChange} />} label="Save Details" />
                                    </InputAdornment>
                                }
                                label="Node host"
                                placeholder="65.21.132.85"
                                color="primary"
                                disabled={loadingData}
                                value={host}
                            />
                        </FormControl>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            [theme.breakpoints.down('md')]: {
                                width: '100%',
                            },
                        }}
                    >
                        <TextField
                            sx={{ width: '100%', mr: 1 }}
                            type="number"
                            label="Api port"
                            id="api-port"
                            color="primary"
                            placeholder="9000"
                            focused
                            disabled={loadingData}
                            value={api_port}
                            onChange={(e) => editHostDetails('api_port', e.target.value)}
                        />
                        <TextField
                            sx={{ width: '100%' }}
                            type="number"
                            label="Metric port"
                            id="metric-port"
                            color="primary"
                            placeholder="9184"
                            focused
                            disabled={loadingData}
                            value={metric_port}
                            onChange={(e) => editHostDetails('metric_port', e.target.value)}
                        />
                    </Box>
                    <LoadingButton
                        color="primary"
                        onClick={getThatAptos}
                        loading={loadingData}
                        sx={{ mt: 3, mb: 1, height: 55, width: { xs: '50%', sm: '50%', md: 125 } }}
                        loadingPosition="start"
                        startIcon={<StreamIcon />}
                        variant="contained"
                    >
                        CONNECT
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    );
};
