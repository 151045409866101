import React, { useContext, useState, createContext, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

import { db } from '../firebase';
import { AppMetaContextParams } from '../Types';

const AppMetaContext = createContext<AppMetaContextParams>({ appMode: 'development', appName: '', appVersion: '', ws: '' } as AppMetaContextParams);

export function useAppMeta() {
    return useContext(AppMetaContext);
}

export const AppMetaProvider = ({ children }: { children: any }) => {
    const [appMode, setAppMode] = useState('development' as 'development' | 'production');
    const [appName, setAppName] = useState('');
    const [appVersion, setAppVersion] = useState('');
    const [ws, setWs] = useState('');

    // const appMetaCollection = collection(db, `app/meta/stage`);
    // useEffect(() => {
    //     onSnapshot(
    //         appMetaCollection,
    //         (snapshot) => {
    //             snapshot.docChanges().forEach(function (change) {
    //                 if (change.doc.id === process.env.REACT_APP_STATUS && (change.type === 'modified' || change.type === 'added')) {
    //                     const appMetaData = change.doc.data() as AppMetaContextParams;
    //                     setAppName(appMetaData.appName);
    //                     setAppVersion(appMetaData.appVersion);
    //                     setAppMode(appMetaData.appMode);
    //                     setWs(appMetaData.ws);
    //                 }
    //             });
    //         },
    //         (error) => {
    //             console.log('error', error.message);
    //         },
    //         () => {
    //             console.log('DONE');
    //         }
    //     );
    // }, []);

    const value: AppMetaContextParams = {
        appMode,
        appName,
        appVersion,
        ws,
    };

    return <AppMetaContext.Provider value={value}>{children}</AppMetaContext.Provider>;
};
