import * as React from 'react';
import { createTheme, Theme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

const ThemeColorContext = React.createContext<{ toggleColorMode: (status: boolean) => void; style: Theme; mode: boolean }>({
    toggleColorMode: () => {},
    style: createTheme(),
    mode: false,
});

export const useTheme = () => {
    return React.useContext(ThemeColorContext);
};

export const ThemeColorProvider = ({ children }: { children: any }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const themeColor = JSON.parse(localStorage.getItem('theme_color')!) || prefersDarkMode;
    const [mode, setMode] = React.useState<boolean>(themeColor);

    React.useEffect(() => {
        setMode(prefersDarkMode);
    }, [prefersDarkMode]);

    const value = React.useMemo(() => {
        return {
            toggleColorMode: (status: boolean) => {
                setMode(status);
                localStorage.setItem('theme_color', String(status));
            },
            style: createTheme({
                palette: {
                    mode: mode ? 'dark' : 'light',
                    primary: {
                        main: '#1976d2',
                    },
                },
            }),
            mode,
        };
    }, [mode]);

    return <ThemeColorContext.Provider value={value}>{children}</ThemeColorContext.Provider>;
};
