// committed: string;
// highest: string;
// synced: string;
// target: string;

export const NodeDataDescription = {
    transactions: {
        type: 'transactions',
        custom_height: false,
        custom_height_px: 0,
        items: [
            {
                name: 'Total Transaction Certificates',
                key: 'total_transaction_certificates',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Total Transaction Effects',
                key: 'total_transaction_effects',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Total Transaction Orders',
                key: 'total_transaction_orders',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },

            {
                name: 'Total Tx Errored',
                key: 'total_tx_errored',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Total Tx Processed',
                key: 'total_tx_processed',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Total Tx Retries',
                key: 'total_tx_retries',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
        ],
    },
    sync: {
        type: 'sync',
        custom_height: false,
        custom_height_px: 0,
        items: [
            {
                name: 'Gossip Sync Count',
                key: 'gossip_sync_count',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Gossip Queued Count',
                key: 'gossip_queued_count',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Gossip Task Error Count',
                key: 'gossip_task_error_count',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Gossip Task Success Count',
                key: 'gossip_task_success_count',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
        ],
    },
    ledger: {
        type: 'ledger',
        custom_height: false,
        custom_height_px: 0,
        items: [
            {
                name: 'Node Host',
                key: 'host',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Node Health',
                key: 'node_health',
                time: false,
                health: true,
                textStyle: 'subtitle1',
            },
            {
                name: 'JSON-RPC Version',
                key: 'json_rpc_version',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Node Type',
                key: 'node_type',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Total Events',
                key: 'total_events',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
        ],
    },
    objects: {
        type: 'objects',
        custom_height: false,
        custom_height_px: 0,
        items: [
            {
                name: 'Shared Objects Count',
                key: 'num_shared_objects_count',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Input Objects Count',
                key: 'num_input_objects_count',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Input Objects Sum',
                key: 'num_input_objects_sum',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'Shared Obj Tx',
                key: 'num_shared_obj_tx',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
        ],
    },
    rpc_request_count: {
        type: 'rpc_request_count',
        custom_height: true,
        custom_height_px: 450,
        items: [
            {
                name: 'rpc.discover',
                key: 'rpc_discover',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransaction',
                key: 'sui_getTransaction',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getObjectsOwnedByAddress',
                key: 'sui_getObjectsOwnedByAddress',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getObject',
                key: 'sui_getObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTotalTransactionNumber',
                key: 'sui_getTotalTransactionNumber',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getObjectsOwnedByObject',
                key: 'sui_getObjectsOwnedByObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsInRange',
                key: 'sui_getTransactionsInRange',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsByInputObject',
                key: 'sui_getTransactionsByInputObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsByMutatedObject',
                key: 'sui_getTransactionsByMutatedObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsToAddress',
                key: 'sui_getTransactionsToAddress',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsFromAddress',
                key: 'sui_getTransactionsFromAddress',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getRecentTransactions',
                key: 'sui_getRecentTransactions',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
        ],
    },
    rpc_request_latency: {
        type: 'rpc_request_latency',
        custom_height: true,
        custom_height_px: 450,
        items: [
            {
                name: 'rpc.discover',
                key: 'rpc_discover',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransaction',
                key: 'sui_getTransaction',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getObjectsOwnedByAddress',
                key: 'sui_getObjectsOwnedByAddress',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getObject',
                key: 'sui_getObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTotalTransactionNumber',
                key: 'sui_getTotalTransactionNumber',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getObjectsOwnedByObject',
                key: 'sui_getObjectsOwnedByObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsInRange',
                key: 'sui_getTransactionsInRange',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsByInputObject',
                key: 'sui_getTransactionsByInputObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsByMutatedObject',
                key: 'sui_getTransactionsByMutatedObject',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsToAddress',
                key: 'sui_getTransactionsToAddress',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getTransactionsFromAddress',
                key: 'sui_getTransactionsFromAddress',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
            {
                name: 'sui_getRecentTransactions',
                key: 'sui_getRecentTransactions',
                time: false,
                health: false,
                textStyle: 'subtitle1',
            },
        ],
    },
};
