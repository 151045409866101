import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { App } from './container/App';

/** Context */
import { ThemeColorProvider, AppMetaProvider, SocketProvider } from './context';

function MainApp() {
    document.title = 'SUI NODETESTER';
    return (
        <ThemeColorProvider>
            <AppMetaProvider>
                <SocketProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SocketProvider>
            </AppMetaProvider>
        </ThemeColorProvider>
    );
}

export default MainApp;
